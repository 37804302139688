let currentPage = 0;
let animElem;

function makeHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');
}

function pasteUrl() {
  try {
    document.querySelector('a[href="#BONUSES_URL"]').setAttribute('href', BONUSES_URL);
    document.querySelector('a[href="#GAME_URL"]').setAttribute('href', GAME_URL);
    document.querySelector('a[href="#RUKH_URL"]').setAttribute('href', RUKH_URL);
    const rulesElem = document.querySelector('.rules p');
    let rulesHtml = rulesElem.innerHTML;
    rulesHtml = rulesHtml.replace(/QUIZ_FROM/g, QUIZ_FROM);
    rulesHtml = rulesHtml.replace(/QUIZ_TO/g, QUIZ_TO);
    rulesHtml = rulesHtml.replace('INSTAGRAM_URL', INSTAGRAM_URL);
    rulesElem.innerHTML = rulesHtml;
  } catch (error) {
    console.log(error);
  }
}

let timeout;

function submit(e) {
  const type = e.type;
  if ((type === 'keypress' && e.keyCode === 13) || type === "click") {
    const inp = document.querySelector('#input');
    const v = inp.value.trim();
    if (v.length > 0) {
      const er = document.querySelector('.error');
      const c = er.closest('.subscribe--container');
      const i = document.querySelector('.subscribe--info');
      clearTimeout(timeout);
      c.classList.remove('success');
      c.classList.remove('invalid');
      i.classList.remove('active');
      fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          email: v
        })
      }).then(function (t) {
        if (!t.ok) {
          throw t
        }
        return t.json();
      }).then(function (r) {
        er.textContent = "Ви підписані. Вітаю!";        
        c.classList.add('success');
        i.classList.add('active');
        timeout = setTimeout(function () {
          c.classList.remove('success');
          i.classList.remove('active');
        }, 5000)
      }).catch(function (e) {
        const status = e.status;
        let err = 'Невідома помилка';
        e.json().then(function (e) {
          if (status === 412) {
            if (e.errors.email) {
              const erm = e.errors.email;
              if (erm === 'required') {
                err = 'Потрібно ввести e-mail'
              } else if (erm === 'wrong_format') {
                err = 'Невірний e-mail'
              } else if (erm === 'exists') {
                err = 'E-mail вже існує'
              }
            }
          } else if (status === 500) {
            if (e.errors.body) {
              err = 'Невірний формат'
            } else if (e.errors.global) {
              if (e.errors.global === 'server_error') {
                err = 'Помилка серверу'
              }
            }
          }
          er.textContent = err;
          c.classList.add('invalid');
          i.classList.add('active');
          timeout = setTimeout(function () {
            c.classList.remove('invalid');
            i.classList.remove('active');
          }, 5000)
        }).catch(function () {
          er.textContent = err;
          c.classList.add('invalid');
          i.classList.add('active');
          timeout = setTimeout(function () {
            c.classList.remove('invalid');
            i.classList.remove('active');
          }, 5000)
        })
      })
    }
  }
}

function forgive(e) {
  const t = e.target;
  const c = t.closest('.subscribe--container');
  const i = document.querySelector('.subscribe--info');
  c.classList.remove('invalid');
  i.classList.remove('active');  
}

function animate() {
  const n = document.querySelectorAll('.animation--btn');
  [...n].forEach((n, i)=>{
    n.classList.add('animated');
    setTimeout(function () {
      n.classList.remove('animated');
      setTimeout(function () {
        animate();
      }, 1100);
    }, 1100)
  })
}

function copyright(){
  const logos = document.querySelector('.logos #copy');
  if(logos !== null){
    logos.innerHTML = '&copy; ' + new Date().getFullYear() + ' ' + COMPANY + ' ' + 'Всі права захищені';
  }
}

function goFaq(e){
  const anim = e.target.closest('.animatable');
  const choos = document.querySelector('.choose');
  anim.classList.add('to-left');
  choos.classList.remove('to-right');
  currentPage = 1;
  animElem = choos.querySelector('.actions');
  prepareRules(animElem);  
}

function goTip(e){
  const anim = e.target.closest('.animatable');
  const faq = anim.nextElementSibling;
  anim.classList.add('to-left');
  faq.classList.remove('to-right');
  currentPage = 2;
  animElem = faq;
  prepareRules(faq);
  setTimeout(()=>{
    document.querySelector('html, body').scrollTo({top: 0, behavior: "smooth"});
  }, 300)
}

function goBack(e){
  const anim = e.target.closest('.animatable');
  const faq = anim.previousElementSibling;
  anim.classList.add('to-right');
  faq.classList.remove('to-left');
  currentPage = 1;
  animElem = faq;
  prepareRules(faq);
}

function prepareRules(target){
  const rules = document.querySelector('.rules');
  const targetTop = target.offsetTop + target.offsetHeight;
  rules.style.top = targetTop + 'px';
  rules.classList.remove('hidden'); 
}

function onOrientationChange(){
  if(currentPage){
    setTimeout(() => prepareRules(animElem), 100);
  }  
}

makeHeight();

// window.onresize = makeHeight;

window.onload = function () {

  document.querySelector('#input').addEventListener('keypress', submit);
  document.querySelector('#input').addEventListener('input', forgive);
  document.querySelector('#subscribe').addEventListener('click', submit, false);
  document.querySelector('#game').addEventListener('click', goFaq, false);
  document.querySelector('.button--faq').addEventListener('click', goTip, false);
  document.querySelector('.faq .back').addEventListener('click', goBack, false);
  window.addEventListener('orientationchange', onOrientationChange)


  pasteUrl();

  copyright();

  animate();
}
